<template>
  <div>
    
    <div class="content">
        <!-- <img src="../../assets/img/index/page-line.png" alt="" class="top-line">
        <div class="nav">
            <span style="color:rgba(51, 51, 51, .6)">首页</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span>数字化交易</span>
        </div> -->
        <img src="../../assets/img/index/44f33508517622e1554212c6b1ef231.png" alt="" style="width:100%;margin-top: 20px;"/>
        <img src="../../assets/img/index/page-line.png" alt="" class="top-line"/>
        <img src="../../assets/img/index/57d4e7bfe862237da13ed3839c8cb03.png" alt="" style="width:100%;margin-top: 20px;"/>
        <img src="../../assets/img/index/page-line.png" alt="" class="top-line"/>
        <img src="../../assets/img/index/e99ec2dd56f7556f444c7e5ef5e2b20.png" alt="" style="width:100%;margin-top: 20px;"/>
    </div>
      
      <!-- <div class="title">数字化交易</div> -->
      <!-- <div class="blurb">
          <div class="left">
              <img src="../../assets/img/index/blurb-logo.png" alt="" class="blurb-logo">
          </div>
          <div class="right">
              <div>平台名称 ：华版数字版权服务中心</div>
              <div>服务宗旨 ：数字版权一站式综合服务</div>
              <div>成立时间 ：2020年</div>
              <div>总部地点 ：中国版权保护中心B座4层</div>
          </div>
      </div> -->
      
      <!-- <div class="one">
        
          <div class="sub-title">
              <div class="name">版权交易平台的定义</div>
          </div>
          <div class="info">
            华版数字版权交易平台是指通过互联网等信息技术手段，为版权所有者和购买方提供版权交易服务的平台。在华版数字版权交易平台上，版权所有者可以将自己的版权作品进行出售或授权，购买方可以通过平台购买自己需要的版权作品或者进行授权交易。          </div>
          <div class="info">
            近年来，随着互联网技术的不断发展，华版数字版权交易平台逐渐成为版权交易的重要方式之一，也成为了促进版权产业发展的重要平台之一。          
          </div>
      </div>
      <div class="three">
          <div class="sub-title">
              <div class="name">中国版权交易平台的重要作用</div>
          </div>
          <div class="info">
              <div>华版数字版权交易平台在促进版权产业发展方面发挥着重要作用。</div>
              <div>首先，华版数字版权交易平台为版权所有者提供了更加便捷的版权交易方式。在传统的版权交易方式中，版权所有者需要通过各种渠道来寻找购买方，交易过程需要耗费大量的时间和精力。而在华版数字版权交易平台上，版权所有者可以通过发布信息的方式来吸引购买方，大大缩短了交易的时间和成本。</div>
              <div>其次，中国版权交易平台为版权产业的规范化发展提供了重要支撑。通过华版数字版权交易平台，版权交易的流程和规范得到了明确，交易过程中的纠纷和风险得到了有效控制，有利于版权产业的健康发展。</div>

          </div>
      </div>
      <div class="three">
          <div class="sub-title">
              <div class="name">华版数字版权交易平台的未来展望</div>
          </div>
          <div class="info">
              <div>随着互联网技术的不断发展和版权意识的不断提高，华版数字版权交易平台的未来展望非常广阔。</div>
              <div>首先，华版数字版权交易平台将进一步促进版权产业的规范化发展。随着版权交易平台的不断完善和发展，版权交易的流程和规范将得到更加明确和完善，有利于版权产业的健康发展。</div>

          </div>
      </div> -->
      
      
  </div>
</template>

<script>
export default {
  name: "Trends",
  components: {

  },
  data() {
    return {
      oneList:[
          {
              name:'版权登记',
              info:'版权登记涉及著作权法第三条所列的作品，如软件、文字作品、口述作品、美术摄影作品、影视作品、音乐作品，录像作品、工程和产品设计图纸及其说明等。华版数字版权综合服务平台避开了传统线下登记的弊端，全部流程从线下迁移到线上，大大缩短了审核时限、提升了效率、降低了成本。'
          },
          {
              name:'版权存证',
              info:'用于版权“快速确权”。将数字作品文件提交至平台，平台会结合签名+时间戳+区块链多种手段，形成不可篡改、不可伪造的证据信息。'
          },
          {
              name:'版权交易',
              info:'为“版权需求者”和“版权拥有者”供需双方搭建平台，让双方更快速的寻找到对方，打破信息不对等的窘况。'
          },
          {
              name:'版权维权服务',
              info:'华版已经形成侵权监测、线上取证、维权一体化服务体系，可极大缩短维权周期与成本。结合多种技术手段实现对版权方的作品内容进行监测，监测结果直达互联网法院，同时若发现侵权及时进行线上取证，进行证据固化，版权人可直接在线上提交维权诉求，提交相关证据材料，达成维权目的。'
          },
          {
              name:'版权交易',
              info:'不定期由版权行业专家对版权权利人进行版权专业知识的培训，帮助版权权利人更好地实现版权的价值。'
          },
          
      ],
      fourList:[
          {
              name:'连接价值',
              info:'平台提供版权交易服务，为“版权需求者”和“版权拥有者”供需双方搭建起便捷的桥梁。'
          },
          {
              name:'行业价值',
              info:'平台可以一站式解决版权相关人员几乎所有需求。'
          },
          {
              name:'数据价值',
              info:'平台汇聚了大量的版权权利人及其海量的不同形式的作品，满足不同需求方的多种版权需求。'
          },
          {
              name:'社会价值',
              info:'应知识产权强国建设的伟大号召，为广大版权权利人的价值实现保驾护航。提升全民版权意识，强化版权管理，优化版权生态。为提升数字版权服务水平以及中国数字经济的崛起贡献一份力量。'
          }
          
      ],
      fiveList:[
          {
              name:'定位',
              info:'数字版权行业全球领先的一站式综合服务平台。'
          },
          {
              name:'使命',
              info:'帮版权的个体利益回归，助创新的社会价值实现。'
          },
          {
              name:'愿景',
              info:'共创、共建，包括版权的持有者、使用者、分发者、交易者、监管者等在内的开放式数字版权生态系统。价值观：连接、开放；共生、共享；可信、可用。'
          }
          
      ]
    };
  },
  mounted() {
    
  },
  methods: {
      
  }
};
</script>

<style scoped lang="scss">
.content{
  width: 1400px;
  margin: 0 auto;
  position: relative;
  .top-line{
      width: 100%;
      height: 85px;
      margin-top: 15px;
  }
  .nav{
      width: 100%;
      height: 85px;
      font-size: 16px;
      font-family: SimSun;
      font-weight: 400;
      color: #333333;
      position: absolute;
      top: 20px;
  }
  .title{
      font-size: 24px;
      font-family: FZCuSong-B09;
      font-weight: 500;
      color: #333333;
      text-align: center;
  }
  .blurb{
      margin-top: 25px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      .left{
          width: 340px;
          height: 326px;
          background-image: url('../../assets/img/index/blurb-bg.png');
          background-repeat: no-repeat;
          background-size: 100%;
          position: relative;
          .blurb-logo{
              width: 198px;
              position:absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              // height: 198px;
          }
      }
      .right{
          font-size: 20px;
          font-family: FZCuSong-B09;
          font-weight: 400;
          color: #333333;
          line-height: 61px;
          margin-left: 63px;
      }

  }
  .sub-title{
    //   width: 334px;
    //   height: 88px;
    //   background-image: url('../../assets/img/index/hz-title.png');
      width: 232px;
      height: 423px;
    //   background-image: url('../../assets/img/index/uswe.png');
      background-image: url('../../assets/img/index/20230524222825.png');
      background-repeat: no-repeat;
      background-size: 100%;
      margin: 0 auto 40px auto;
      .name{
        //   background: ;
          font-size: 30px;
          font-family: FZLiShu-S01S;
          font-weight: 400;
          color: #000;
          line-height: 60px;
        //   text-indent:2em;
          font-weight: bold;
          text-align: center;
      }
      .enname{
          // text-indent:3.4em;
          font-size: 16px;
          font-family: FZHuaLi-M14;
          font-weight: 400;
          color: #333333;
          opacity: 0.5;
          margin-top: -20px;
      }
  }
  .one{
      margin-top: 42px;
      .info{
          text-indent:2em;
          font-size: 20px;
          font-family: SimSun;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
      }
      .enname{
          text-indent:3.2em;
      }
  }
  .two,.four{
      margin-top: 50px;
      .enname{
          text-indent:3.4em;
      }
      .info{
          .info-item{
              margin-bottom: 30px;
              display: flex;
              flex-wrap: nowrap;
              
              .left{
                  width: 179px;
                  height: 51px;
                  position:relative;
                  .name{
                      width: 179px;
                      height: 51px;
                      font-size: 20px;
                      font-family: FZCuSong-B09;
                      font-weight: 400;
                      color: #000000;
                      text-align: center;
                      line-height: 51px;
                      position:absolute;
                      left: 0;
                      top: 0;
                  }
                  .jj-left{
                      width: 179px;
                      height: 51px;
                      background-image:url('../../assets/img/index/jj-left.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      left: 10px;
                      bottom: 0;
                  }
                  .jj-right{
                      width: 162px;
                      height: 50px;
                      background-image:url('../../assets/img/index/jj-right.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      right: 0;
                      top: 0;
                  }
              }
              .right{
                  line-height: 30px;
                  width: calc( 100% - 220px);
                  font-size: 20px;
                  font-family: SimSun;
                  font-weight: 400;
                  color: #333333;
                  margin-left: 32px;
                  margin-top: 12px;
              }
          }
      }
  }
  .three{
      margin-top: 82px;
      .enname{
          text-indent:3.4em;
      }
      .info{
          
          font-size: 20px;
          font-family: SimSun;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
          div{
              text-indent:2em;
          }
      }
  }

  .five{
      margin-top: 50px;
      .enname{
          text-indent:3.4em;
      }
      .info{
          .info-item{
              margin-bottom: 30px;
              display: flex;
              flex-wrap: nowrap;
              
              .left{
                  width: 108px;
                  height: 50px;
                  position:relative;
                  .name{
                      width: 108px;
                      height: 50px;
                      font-size: 20px;
                      font-family: FZCuSong-B09;
                      font-weight: 400;
                      color: #FFFFFF;
                      text-align: center;
                      line-height: 40px;
                      position:absolute;
                      left: 0;
                      top: 0;
                  }
                  .jj-left{
                      width: 108px;
                      height: 50px;
                      background-image:url('../../assets/img/index/wh-bg.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      left: 0;
                      bottom: 0;
                  }
                  
              }
              .right{
                  line-height: 30px;
                  width: calc( 100% - 220px);
                  font-size: 20px;
                  font-family: SimSun;
                  font-weight: 400;
                  color: #333333;
                  margin-left: 32px;
                  margin-top: 12px;
              }
          }
      }
  }
  .six{
      margin-top: 50px;
      height: 1000px;
      .enname{
          text-indent:3.4em;
      }
      .info{
          .info-item1{
              display: flex;
              flex-wrap: nowrap;
              margin-bottom: 80px;
              .left{
                  width: 108px;
                  height: 50px;
                  position:relative;
                  margin-top: 20px;
                  .name{
                      width: 108px;
                      height: 50px;
                      font-size: 20px;
                      font-family: FZCuSong-B09;
                      font-weight: 400;
                      color: #FFFFFF;
                      text-align: center;
                      line-height: 40px;
                      position:absolute;
                      left: 0;
                      top: 0;
                  }
                  .jj-left{
                      width: 108px;
                      height: 50px;
                      background-image:url('../../assets/img/index/zp-bg.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      left: 0;
                      bottom: 0;
                  }
                  
              }
              .right{
                  width: calc(100% - 108px);
                  height:330px;
                  position: relative;
                  margin-left: 20px;
                  .r-left{
                      width: 1154px;
                      height: 315px;
                      background-image:url('../../assets/img/index/sj-left.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      // left: 10px;
                      // bottom: 0;
                  }
                  .r-right{
                      width: 972px;
                      height: 259px;
                      background-image:url('../../assets/img/index/sj-right.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      right: 10px;
                      bottom: 0;
                  }
                  ul{
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      left: 0;
                      right: 0;
                      padding: 50px 70px;
                      li{
                          font-size: 20px;
                          font-family: SimSun;
                          font-weight: 400;
                          color: #333333;
                          line-height: 30px;
                      }
                      li::marker{
                          color: #9D1610;
                      }
                  }
              }
          }
          .info-item2{
              display: flex;
              flex-wrap: nowrap;
              
              .left{
                  width: 108px;
                  height: 50px;
                  position:relative;
                  margin-top: 20px;
                  .name{
                      width: 108px;
                      height: 50px;
                      font-size: 20px;
                      font-family: FZCuSong-B09;
                      font-weight: 400;
                      color: #FFFFFF;
                      text-align: center;
                      line-height: 40px;
                      position:absolute;
                      left: 0;
                      top: 0;
                  }
                  .jj-left{
                      width: 108px;
                      height: 50px;
                      background-image:url('../../assets/img/index/zp-bg.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      left: 0;
                      bottom: 0;
                  }
                  
              }
              .right{
                  width: calc(100% - 108px);
                  height:460px;
                  position: relative;
                  margin-left: 20px;
                  .r-left{
                      width: 1154px;
                      height: 315px;
                      background-image:url('../../assets/img/index/sj-left.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      // left: 10px;
                      // bottom: 0;
                  }
                  .r-right{
                      width: 972px;
                      height: 259px;
                      background-image:url('../../assets/img/index/sj-right.png');
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      right: 10px;
                      bottom: 0;
                  }
                  ul{
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      left: 0;
                      right: 0;
                      padding: 50px 70px;
                      li{
                          font-size: 20px;
                          font-family: SimSun;
                          font-weight: 400;
                          color: #333333;
                          line-height: 30px;
                      }
                      li::marker{
                          color: #9D1610;
                      }
                  }
              }
          }
      }
  }
}

</style>

